<template>
    <div class="columns is-centered">
        <div class="column is-four-fifths is-full-touch">
            <p class="TextEulaEs">
                <strong>{{ i18n('End-User License Agreement') }}</strong><br>
                <br>
                This End-User License Agreement (EULA) is a legal agreement between you
                (either as an individual or on behalf of an entity) and Aumentar SAS regarding
                your use of LLS Incentives desktop applications, and associated documentation
                (the "Software"). IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THIS EULA, DO NOT
                INSTALL, USE OR COPY THE SOFTWARE.<br>
                <strong>Summary.</strong><br>
                You must agree to all of the terms of this EULA to use this Software.<br>
                If so, you may use the Software for free and for any lawful purpose.<br>
                This Software automatically communicates with its server for three reasons:
                (1) to receive and install updates; (2) to send error reports; and (3) to send
                anonymized usage information. You can view sample data to see what information
                is sent, and you may opt out of sending the anonymized usage data. This Software
                is provided "as-is" with no warranties, and you agree that Aumentar SAS is not
                liable for anything you do with it.<br>
                You really ought to just go ahead and read the whole EULA. It's not all that long.
                You should not only rely on this summary.<br>
                <strong>The Agreement.</strong><br>
                By downloading, installing, using, or copying the Software, you accept and agree to
                be bound by the terms of this EULA. If you do not agree to all of the terms of this
                EULA, you may not download, install, use or copy the Software.<br>
                <strong>The License.</strong><br>
                This EULA entitles you to install as many copies of the Software as you want, and
                use the Software for any lawful purpose consistent with this EULA. Your license to
                use the Software is expressly conditioned upon your agreement to all of the terms of
                this EULA. This software is licensed, not sold.
                Aumentar SAS reserves all other rights not granted by this EULA.<br>
                <strong>The Restrictions.</strong><br>
                When using the Software you must use it in a manner that complies with the
                applicable laws in the jurisdiction(s) in which you use the Software.
                You may not sell, resell, rent, lease or exchange the Software for anything of
                value.
                You may redistribute the software, but it must include this EULA and you may not
                repackage or bundle the Software with any other software.
                You may not remove or alter any proprietary notices or marks on the Software.<br>
                <strong>Privacy Notices.</strong><br>
                The Software automatically communicates with its server for three purposes: (1)
                updating the Software; (2) sending error reports; and (3) sending anonymized usage
                data so we may improve the Software. If you would like to learn more about the
                specific information we send, please visit https://lls.aumentar.com.co/docs/privacy .
                You may opt out of sending the anonymized usage data, but if you do not want the
                Software to update automatically or send error reports, you must uninstall the
                Software.<br>
                <strong>Automatic Software Updates.</strong><br>
                The Software communicates with its server (and sends information described at the
                URL above) to determine whether there are any patches, bug fixes, updates, upgrades
                or other modifications to improve the Software. You agree that the Software may
                automatically install any such improvements to the Software on your computer without
                providing any further notice or receiving any additional consent. This feature may
                not be disabled. If you do not want to receive automatic updates, you must uninstall
                the Software.<br>
                <strong>Error Reports.</strong><br>
                In order to help us improve the Software, when the Software encounters certain
                errors, it will automatically send some information to its server about the error
                (as described at the URL above). This feature may not be disabled. If you do not
                want to send error reports to its server, you must uninstall the Software.<br>
                <strong>Anonymized Usage Data.</strong><br>
                LLS Incentives collects anonymized data about your usage of the Software to help us
                make it more awesome. Approximately once a day the Software sends such data (as
                described in more detail at the URL above) to its server. If you do not want to
                send anonymized usage data to the server, you may opt out by changing your settings
                in the Preferences view.<br>
                <strong>Open-Source Notices.</strong><br>
                Certain components of the Software may be subject to open-source software
                licenses ("Open-Source Components"), which means any software license approved
                as open-source licenses by the Open Source Initiative or any substantially similar
                licenses, including without limitation any license that, as a condition of
                distribution of the software licensed under such license, requires that the
                distributor make the software available in source code format. The Software
                documentation includes copies of the licenses applicable to the Open-Source
                Components.<br>
                To the extent there is conflict between the license terms covering the Open-Source
                Components and this EULA, the terms of such licenses will apply in lieu of the terms
                of this EULA. To the extent the terms of the licenses applicable to Open-Source
                Components prohibit any of the restrictions in this Agreement with respect to such
                Open-Source Component, such restrictions will not apply to such Open-Source
                Component. To the extent the terms of the licenses applicable to Open-Source
                Components require Licensor to make an offer to provide source code in connection
                with the Product, such offer is hereby made, and you may exercise it by
                contacting gerencia@aumentar.com.co .<br>
                <strong>Intellectual Property Notices.</strong><br>
                The Software and all worldwide copyrights, trade secrets, and other intellectual
                property rights therein are the exclusive property of Aumentar SAS. Aumentar SAS
                reserves all rights in and to the Software not expressly granted to you in this
                EULA.<br>
                <strong>Disclaimers and Limitations on Liability.</strong><br>
                THE SOFTWARE IS PROVIDED ON AN "AS IS" BASIS, AND NO WARRANTY, EITHER EXPRESS OR
                IMPLIED, IS GIVEN. YOUR USE OF THE SOFTWARE IS AT YOUR SOLE RISK. Aumentar SAS does
                not warrant that (i) the Software will meet your specific requirements; (ii) the
                Software is fully compatible with any particular platform; (iii) your use of the
                Software will be uninterrupted, timely, secure, or error-free; (iv) the results
                that may be obtained from the use of the Software will be accurate or reliable; (v)
                the quality of any products, services, information, or other material purchased or
                obtained by you through the Software will meet your expectations; or (vi) any errors
                in the Software will be corrected.<br>
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT Aumentar SAS SHALL NOT BE LIABLE FOR ANY
                DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
                INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR
                OTHER INTANGIBLE LOSSES (EVEN IF Aumentar SAS HAS BEEN ADVISED OF THE POSSIBILITY
                OF SUCH DAMAGES) RELATED TO THE SOFTWARE, including, for example: (i) the use or the
                inability to use the Software; (ii) the cost of procurement of substitute goods and
                services resulting from any goods, data, information or services purchased or
                obtained or messages received or transactions entered into through or from the
                Software; (iii) unauthorized access to or alteration of your transmissions or data;
                (iv) statements or conduct of any third-party on the Software; (v) or any other
                matter relating to the Software.<br>
                Aumentar SAS reserves the right at any time and from time to time to modify or
                discontinue, temporarily or permanently, the Software (or any part thereof) with or
                without notice. Aumentar SAS shall not be liable to you or to any third-party for
                any modification, price change, suspension or discontinuance of the Software.<br>
            </p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Privacy',
    inject: ['i18n', 'http', 'route'],

    computed: {
    },
};
</script>

<style lang="scss">

.TextEulaEs{
    text-align: justify;
}

</style>
